<!--
 * @Author: pengyu
 * @Date: 2021-06-21 10:44:59
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-21 13:48:35
 * @Description: office-oline
-->
<template>
 <div class="container">
   <iframe :src="src" frameborder="0" width="100%" height="100%"></iframe>
 </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import { base64Encode } from "@/tools/util";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default {
  name: "OfficeOnline",
  data() {
    return {
      src: ""
    };
  },
  computed: {
    ...mapGetters(["urlParam"])
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { openType, extension, fileCode } = this.$route.query;
      const { oosUrl, wopiUrl } = this.urlParam;

      if (!fileCode) {
        Message({
          type: "warning",
          message: "未读取到文件"
        });
        return;
      }

      let api = "";
      const { add } = BusinessDataOpenType;
      switch (extension) {
          case "doc":
              api = openType === add ? "wv/wordviewerframe.aspx?WOPISrc=" : null;
              break;
          case "docx":
              api = openType === add ? "wv/wordviewerframe.aspx?embed=1&WOPISrc=" : "wv/wordviewerframe.aspx?WOPISrc=";
              break;
          case "ppt":
              api = openType === add ? "p/PowerPointFrame.aspx?PowerPointView=ReadingView&WOPISrc=" : null;
              break;
          case "pptx":
              api = openType === add ? "p/PowerPointFrame.aspx?PowerPointView=ChromelessView&Embed=1&WOPISrc=" : "p/PowerPointFrame.aspx?PowerPointView=ReadingView&WOPISrc=";
              break;
          case "xls":
              api = openType === add ? "x/_layouts/xlviewerinternal.aspx?embed=1&WOPISrc=" : null;
              break;
          case "xlsx":
              api = openType === add ? "x/_layouts/xlembed.aspx?WOPISrc=" : "x/_layouts/xlviewerinternal.aspx?WOPISrc=";
              break;
          case "pdf":
              api = openType === add ? "wv/wordviewerframe.aspx?PdfMode=1&WOPISrc=" : null;
              break;
          default:
              break;
      }

      if (!api) {
        Message({
          type: "error",
          message: "不支持的打开方式"
        });
        return;
      }

      const code = base64Encode(fileCode);
      this.src = `${`${process.env.VUE_APP_OFFICEONLINE}/${ api }${process.env.VUE_APP_OFFICEFIELD}` }/wopi/files/${ code }`;

      // process.env.VUE_APP_OFFICEFIELD;

      // https:// enoffice.softheluo.com/wv/wordviewerframe.aspx?embed=1&WOPISrc=http://storage.dev.softheluo.com/wopi/files/aGVsdW8vMS8xMTYzNDc1ODkxMzI4NTkwODE0MjQyOTk1NDkyNzE4Ny5kb2N4.docx

// this.src = `https://enoffice.softheluo.com/wv/wordviewerframe.aspx?PdfMode=1&WOPISrc=${ process.env.VUE_APP_OFFICEFIELD}/`;

// this.src = `${process.env.VUE_APP_OFFICEONLINE }/wv/wordviewerframe.aspx?embed=1&WOPISrc=${ process.env.VUE_APP_OFFICEFIELD}/wopi/files/${ code }.${ extension}`;
    }
  }
};
</script>
